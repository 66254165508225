<template>
    <div class="flex flex-col mx-auto px-4 sm:px-6 lg:px-8 max-w-7xl">
        <div ref="container" class="relative mt-14 md:mt-32 pb-40">
            <section>
                <div class="grid grid-cols-12 lg:gap-20 items-start">
                    <div class="order-2 mt-20 lg:mt-0 relative col-span-12 lg:col-span-6 lg:pt-0">
                        <div class="flex flex-col gap-y-52">
                            <HeroItem>
                                <h1 class="text-2xl md:text-3xl lg:text-4xl text-balance">
                                    <span class="font-bold">{{ $t('moto') }} </span>
                                </h1>

                                <p
                                    class="text-base text-balance max-w-lg md:text-xl font-medium leading-5 text-gray-500"
                                >
                                    {{ $t('description') }}
                                </p>

                                <UserEarlyAccessForm :ui="{ label: { base: 'text-black' } }" />
                            </HeroItem>
                            <HeroItem
                                header="Simple control"
                                subheader="Command the scoreboard with ease. Tap the buttons to update the scores."
                            >
                                <ControlApp class="rotate-2" />
                            </HeroItem>
                            <HeroItem
                                header="Adapts to any sporting discipline"
                                subheader="At the push of a button switch between sports to get the right scoreboard for your game."
                            >
                                <div class="flex flex-col">
                                    <CommonToggleGroup v-model="selected" :items="items" />

                                    <NuxtLink to="/sports" class="mt-5 w-fit text-sm hover:underline text-blue-500">
                                        View all supported sports
                                    </NuxtLink>
                                </div>
                            </HeroItem>
                            <HeroItem
                                header="Scores sync to the cloud"
                                subheader="Scores are live synced to the cloud, anyone can follow along."
                            >
                                <ClientOnly>
                                    <LiveSync />
                                </ClientOnly>
                            </HeroItem>
                            <HeroItem
                                header="Download our app for iOS or Android."
                                subheader="The app acts as the remote control for the scoreboard."
                            >
                                <div class="flex gap-4">
                                    <a href="https://apple.com">
                                        <NuxtImg class="w-44" src="apple_app_store.png" />
                                    </a>
                                    <a href="https://google.com">
                                        <NuxtImg class="w-44" src="google_play_store.png" />
                                    </a>
                                </div>
                            </HeroItem>
                        </div>
                    </div>
                    <div
                        ref="scoreboard"
                        class="z-20 order-1 sticky top-24 lg:top-32 col-span-12 lg:col-span-6 w-52 md:w-96 lg:w-full mx-auto"
                    >
                        <ScoreboardGrid />
                    </div>
                </div>
            </section>
        </div>
        <div class="flex">
            <HeroSectionCallToAction />
        </div>
    </div>
</template>

<script setup lang="ts">
const items = [
    {
        value: 'canoe-polo',
        label: 'Canoe Polo',
        description: '',
        icon: 'i-ic-round-kayaking',
    },
    {
        value: 'netball',
        label: 'Netball',
        description: '',
        icon: 'i-icon-park-outline-volleyball',
    },
    {
        value: 'hockey',
        label: 'Hockey',
        description: '',
        icon: 'i-icon-park-outline-hockey',
    },
];

const { setSportType } = useScoreboardStore();
const router = useRouter();
const route = useRoute();
const selected = computed({
    get() {
        const item = items.find((item) => item.value === route.query.tab);
        if (!item) {
            return items[0].value;
        }
        return item.value;
    },
    set(value) {
        // Hash is specified here to prevent the page from scrolling to the top
        router.replace({ query: { tab: value }, hash: '#sport' });
        setSportType(value);
    },
});
</script>
