<template>
    <div>
        <div class="flex flex-col items-center justify-center">
            <h1 class="text-3xl font-bold">LiveSync</h1>

            <!-- Display notifications to verify they are updating -->
            <pre>{{ notifications }}</pre>
            <!-- Use <pre> to format JSON nicely -->
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';

const api = useApi();
const notifications = ref<any[]>([]);

onMounted(() => {
    console.log('Component mounted, subscribing to notifications...');

    // Subscribe to the global channel and update notifications on new events
    api.v1.notifications.subscribeToGlobalChannel((event: any) => {
        console.log('event received:', event); // Debugging log to verify event receipt
        notifications.value.push(event); // Update the reactive state
        console.log('Updated notifications:', notifications.value); // Verify notifications are updated
    });
});
</script>

<style scoped></style>
